import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Links from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { UserDataContainer } from "../../../styles/style";
import { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { Button, IconButton, Input, iconButtonClasses } from "@mui/joy";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import { baseURL } from "../../../../config";

const CivilEngineering = () => {
  const [workers, setWorkers] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  // const [page] = useState<number>(1);
  // const [perPage] = useState<number>(10);
  const [paginationData, setPaginationData] = useState<any>({
    after_filtering_count: 0,
    current_page: 1,
    next_page: null,
    page_count: 0,
  });
  const [page, setPage] = useState(paginationData.current_page);

  const [after_filtering_count, setAfter_filtering_count] = useState<number>(0);

  const handlePageChange = async (newPage: number) => {
    await fetchWorkers(newPage, search);
    setPage(newPage);
  };

  const fetchWorkers = async (page: number, search: string) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(`${baseURL}/worker/my-all`, {
        params: {
          search,
          page,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setAfter_filtering_count(res.data?.after_filtering_count || 0);

      const data = Array.isArray(res.data) ? res.data : res.data.data;
      setWorkers(data || []);
      setPaginationData({
        after_filtering_count: res.data.after_filtering_count,
        current_page: res.data.current_page,
        next_page: res.data.next_page,
        page_count: res.data.page_count,
      });
    } catch (error) {
      console.error("Failed to fetch workers:", error);
    }
  };

  // useEffect(() => {
  //   fetchWorkers();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page, perPage]);
  const handleSearch = () => {
    fetchWorkers(1, search);
  };

  const handleReset = () => {
    setSearch("");
    setPage(1);
    fetchWorkers(1, "");
  };
  const renderPageNumbers = () => {
    const pages = [];
    const totalNumbers = 6;
    const sideNumbers = 1;

    if (paginationData.page_count <= totalNumbers) {
      for (let i = 1; i <= paginationData.page_count; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(2, page - sideNumbers);
      let endPage = Math.min(paginationData.page_count - 1, page + sideNumbers);

      if (page <= sideNumbers + 2) {
        startPage = 2;
        endPage = totalNumbers - 1;
      } else if (page >= paginationData.page_count - sideNumbers - 1) {
        startPage = paginationData.page_count - totalNumbers + 2;
        endPage = paginationData.page_count - 1;
      }

      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < paginationData.page_count - 1) pages.push("...");
      pages.push(paginationData.page_count);
    }

    return pages;
  };
  const handleDownload = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(baseURL + "/worker/download-excel", {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = urlObject;
      const contentDisposition = response.headers.get("content-disposition");
      let fileName = "download.xlsx";
      if (contentDisposition && contentDisposition.includes("filename=")) {
        fileName = contentDisposition
          .split("filename=")[1]
          .replace(/['"]/g, "")
          .trim();
      }
      a.download = fileName;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          //   minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Links
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Links>

            <Typography color="primary" fontWeight={500} fontSize={12}>
              토목 데이터
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography level="h2" component="h1" sx={{ flex: 1 }}>
              토목 데이터
            </Typography>
          </Box>
          <div>
            <UserDataContainer style={{ width: 300 }}>
              <h1>Total:</h1>
              <p>{after_filtering_count}</p>
            </UserDataContainer>
          </div>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <Button color="success" onClick={handleDownload}>
              Download Excel
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "end",
              mb: 4,
              alignItems: "center",
            }}
          >
            <Input
              placeholder="Search in here…"
              sx={{ flex: 2 }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* <Select
          placeholder="Country"
          indicator={<KeyboardArrowDown />}
          sx={{
            flex: 2,
            [`& .${selectClasses.indicator}`]: {
              transition: "0.2s",
              [`&.${selectClasses.expanded}`]: {
                transform: "rotate(-180deg)",
              },
            },
          }}
        >
          <Option value="dog">Dog</Option>
          <Option value="cat">Cat</Option>
          <Option value="fish">Fish</Option>
          <Option value="bird">Bird</Option>
        </Select>
        <Select
          placeholder="Major"
          indicator={<KeyboardArrowDown />}
          sx={{
            flex: 1,
            [`& .${selectClasses.indicator}`]: {
              transition: "0.2s",
              [`&.${selectClasses.expanded}`]: {
                transform: "rotate(-180deg)",
              },
            },
          }}
        >
          <Option value="dog">Dog</Option>
          <Option value="cat">Cat</Option>
          <Option value="fish">Fish</Option>
          <Option value="bird">Bird</Option>
        </Select>

        <Select
          placeholder="Experience"
          indicator={<KeyboardArrowDown />}
          sx={{
            flex: 1,
            [`& .${selectClasses.indicator}`]: {
              transition: "0.2s",
              [`&.${selectClasses.expanded}`]: {
                transform: "rotate(-180deg)",
              },
            },
          }}
        >
          <Option value="starter">신입</Option>
          <Option value="junior">1-3</Option>
          <Option value="middle">4-6</Option>
          <Option value="senior">6-10</Option>
          <Option value="superman">10+</Option>
        </Select>
        <Select
          placeholder="TOPIK"
          indicator={<KeyboardArrowDown />}
          sx={{
            flex: 1,
            [`& .${selectClasses.indicator}`]: {
              transition: "0.2s",
              [`&.${selectClasses.expanded}`]: {
                transform: "rotate(-180deg)",
              },
            },
          }}
        >
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
          <Option value="4">4</Option>
          <Option value="5">5</Option>
          <Option value="6">6</Option>
        </Select> */}

            <Button onClick={handleSearch}>Search</Button>
            <Button variant="soft" onClick={handleReset}>
              RESET
            </Button>
          </Box>

          <Sheet variant="soft" sx={{ pt: 1, borderRadius: "sm" }}>
            <Table
              stripe="odd"
              hoverRow
              sx={{
                captionSide: "top",
                "& tbody": { bgcolor: "background.surface" },
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "60px" }}>No</th>
                  <th style={{ width: "25%" }}>Ism</th>
                  <th>Familya</th>
                  <th>Davlat</th>
                  <th>Email</th>
                  <th>Telefon</th>
                  <th style={{ width: "60px" }}></th>
                </tr>
              </thead>
              <tbody>
                {workers && workers.length > 0 ? (
                  workers.map((row, index) => (
                    <tr key={row.id}>
                      <td>{index + 1}</td>
                      <td>{row.first_name}</td>
                      <td>{row.last_name}</td>
                      <td>{row.country}</td>
                      <td>{row.email}</td>
                      <td>{row.phone}</td>
                      <td>
                        <Link
                          to={`${row._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            color="primary"
                            sx={{ cursor: "pointer" }}
                          >
                            view
                          </Typography>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      style={{ textAlign: "center", padding: "1rem" }}
                    >
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Sheet>
        </Box>
        <Box
          className="Pagination-laptopUp"
          sx={{
            pt: 2,
            gap: 1,
            [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Button
            size="sm"
            variant="outlined"
            color="neutral"
            startDecorator={<KeyboardArrowLeftIcon />}
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
          >
            Previous
          </Button>
          <Box sx={{ flex: 1 }} />
          {renderPageNumbers().map((pageNumber, index) => (
            <IconButton
              key={index}
              size="sm"
              variant={pageNumber === page ? "solid" : "outlined"}
              color="neutral"
              onClick={() =>
                typeof pageNumber === "number" && handlePageChange(pageNumber)
              }
              disabled={pageNumber === "..."}
            >
              {pageNumber}
            </IconButton>
          ))}
          <Box sx={{ flex: 1 }} />
          <Button
            size="sm"
            variant="outlined"
            color="neutral"
            endDecorator={<KeyboardArrowRightIcon />}
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= paginationData.page_count}
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CivilEngineering;
