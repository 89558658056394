import { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { Box, Button, Input, Typography } from "@mui/joy";
// import Select, { selectClasses } from "@mui/joy/Select";
// import Option from "@mui/joy/Option";
// import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import { baseURL } from "../../../../config";
import { UserDataContainer } from "../../../styles/style";

const ArchitectTable = () => {
  const [workers, setWorkers] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [page] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [after_filtering_count, setAfter_filtering_count] = useState<number>(0);

  const fetchWorkers = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(baseURL + "/worker/all", {
        params: {
          search,
          page,
          per_page: perPage,
          category: "mechanical engineer",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAfter_filtering_count(res.data?.after_filtering_count || 0);

      const data = Array.isArray(res.data) ? res.data : res.data.data;
      setWorkers(data || []);
    } catch (error) {
      console.error("Failed to fetch workers:", error);
    }
  };

  useEffect(() => {
    fetchWorkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const handleSearch = () => {
    fetchWorkers();
  };

  const handleReset = () => {
    setSearch("");
    fetchWorkers();
  };

  const handleDownload = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(baseURL + "/worker/download-excel", {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = urlObject;
      const contentDisposition = response.headers.get("content-disposition");
      let fileName = "download.xlsx";
      if (contentDisposition && contentDisposition.includes("filename=")) {
        fileName = contentDisposition
          .split("filename=")[1]
          .replace(/['"]/g, "")
          .trim();
      }
      a.download = fileName;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography level="h2" component="h1" sx={{ flex: 1 }}>
            건축가 [E-7-1]
          </Typography>
        </Box>
        <div>
          <UserDataContainer style={{ width: 300 }}>
            <h1>Total:</h1>
            <p>{after_filtering_count}</p>
          </UserDataContainer>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            paddingY: 2,
            fontSize: 30,
            fontWeight: 600,
          }}
        ></Typography>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <Button color="success" onClick={handleDownload}>
            엑셀 다운로드
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "end",
          mb: 4,
          alignItems: "center",
        }}
      >
        <Input
          placeholder="Search in here…"
          sx={{ flex: 2 }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {/* <Select defaultValue="molder" sx={{ width: 150 }}>
          <Option value="molder">성형공</Option>
          <Option value="welder">용접공</Option>
          <Option value="painter">도장공</Option>
          <Option value="mold-maker">금형공</Option>
        </Select> */}
        <Button onClick={handleSearch}>Search</Button>
        <Button variant="soft" onClick={handleReset}>
          RESET
        </Button>
      </Box>

      <Sheet variant="soft" sx={{ pt: 1, borderRadius: "sm" }}>
        <Table
          stripe="odd"
          hoverRow
          sx={{
            captionSide: "top",
            "& tbody": { bgcolor: "background.surface" },
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "60px" }}>No</th>
              <th style={{ width: "25%" }}>이름</th>
              <th>전문</th>
              <th>위치</th>
              <th>국가</th>
              <th>이메일</th>
              <th>전화 번호</th>
              <th style={{ width: "60px" }}></th>
            </tr>
          </thead>
          <tbody>
            {workers && workers.length > 0 ? (
              workers.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.first_name}</td>
                  <td>{row.work_experience[0].specialty}</td>
                  <td>{row.work_experience[0].position}</td>
                  <td>{row.country}</td>
                  <td>{row.email}</td>
                  <td>{row.phone}</td>

                  <td>
                    <Link
                      to={`/outsourcing-civilengineering-data/${row._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography color="primary" sx={{ cursor: "pointer" }}>
                        view
                      </Typography>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={8}
                  style={{ textAlign: "center", padding: "1rem" }}
                >
                  No data found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Sheet>
    </Box>
  );
};

export default ArchitectTable;
