import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Link,
  Sheet,
  Table,
  Typography,
} from "@mui/joy";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import ChecklistIcon from "@mui/icons-material/Checklist";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CreateIcon from "@mui/icons-material/Create";
import AttendanceModal from "../../../modals/AttendanceModal";
import { useEffect, useState } from "react";
import StudentEditModal from "../../../modals/StudentEditModal";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../../../config";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import JSZip from "jszip";
import { saveAs } from "file-saver";
interface WeldingStudent {
  _id: string;
  name: string;
  is_attendance: number;
  id_front: string;
  id_back: string;
  photo_3x4: string;
  consent: string;
  security: string;
}

const WeldingStudents = () => {
  const [attendanceModalOpen, setAttendanceModalOpen] = useState<string>("");

  const [openEdit, setOpenEdit] = useState<string>("");

  const navigate = useNavigate();

  const [students, setStudents] = useState<WeldingStudent[]>([]);
  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${baseURL}/welding-student/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setStudents(response.data.data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const onDelete = async (userID: string) => {
    if (!window.confirm("Are you sure you want to delete")) {
      return;
    }
    try {
      await axios.delete(`${baseURL}/welding-student/delete/${userID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      fetchStudents();
    } catch (error) {
      console.error("Error deleting student:", error);
    }
  };

  const downloadAllAsZip = async () => {
    try {
      const zip = new JSZip();

      if (!students || students.length === 0) {
        toast.info("Students not found!");
        return;
      }

      for (const student of students) {
        const studentFolder = zip.folder(student.name);
        if (!studentFolder) continue;

        const fileUrls = {
          id_front: student.id_front,
          id_back: student.id_back,
          photo_3x4: student.photo_3x4,
          consent: student.consent,
          security: student.security,
        };

        for (const [key, fileUrl] of Object.entries(fileUrls)) {
          if (!fileUrl) continue;

          try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
              console.warn(`Failed to download file: ${fileUrl}`);
              continue;
            }
            const blob = await response.blob();
            const ext = fileUrl.split(".").pop();
            const fileName = `${key}.${ext}`;
            studentFolder.file(fileName, blob);
          } catch (error) {
            console.error(`Error fetching file: ${fileUrl}`, error);
          }
        }
      }

      const zipContent = await zip.generateAsync({ type: "blob" });
      saveAs(zipContent, "Students_Files.zip");
    } catch (err) {
      console.error("Error creating ZIP file", err);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon />}
          sx={{ pl: 0 }}
        >
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home"
          >
            <HomeRoundedIcon />
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            학원
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            Welding students
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
          mb: 2,
        }}
      >
        <Button
          onClick={() => navigate("/education/welding-attendance-list")}
          startDecorator={<ChecklistIcon />}
        >
          Attendance List
        </Button>
        <Button startDecorator={<FolderZipIcon />} onClick={downloadAllAsZip}>
          Download ZIP
        </Button>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: 248,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                Full Name
              </th>
              <th style={{ padding: "12px 10px", textAlign: "center" }}>
                Status
              </th>
              <th style={{ padding: "12px 10px", textAlign: "center" }}>
                Download
              </th>
              <th style={{ padding: "12px 10px", textAlign: "end" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {students?.map((user, index) => (
              <tr key={user._id}>
                <td style={{ padding: "12px 10px", textAlign: "center" }}>
                  {user.name}
                </td>

                <td style={{ padding: "12px 10px", textAlign: "center" }}>
                  {user.is_attendance === -1 ? (
                    <RadioButtonCheckedIcon color="action" />
                  ) : user.is_attendance === 1 ? (
                    <CheckBoxIcon color="success" />
                  ) : user.is_attendance === 0 ? (
                    <CloseIcon color="warning" />
                  ) : (
                    ""
                  )}
                </td>
                <td style={{ padding: "12px 10px", textAlign: "center" }}>
                  <a href={user.consent}>Consent</a> <br />
                  <a href={user.security}>Security</a>
                </td>
                <td
                  style={{
                    padding: "16px 10px",
                    textAlign: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <ButtonGroup aria-label="Basic button group">
                      <Button
                        onClick={() => setAttendanceModalOpen(user?._id)}
                        startDecorator={<CheckBoxIcon />}
                        color="success"
                      >
                        Attendance Check
                      </Button>
                      <Button
                        onClick={() => setOpenEdit(user._id)}
                        startDecorator={<CreateIcon />}
                        color="primary"
                      >
                        Edit
                      </Button>
                      {JSON.parse(localStorage.getItem("user") as any).role ===
                        "SUPER_ADMIN" && (
                        <Button
                          onClick={() => onDelete(user?._id)}
                          color="danger"
                          startDecorator={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      )}
                    </ButtonGroup>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <AttendanceModal
        open={!!attendanceModalOpen}
        onClose={() => setAttendanceModalOpen("")}
        studentId={attendanceModalOpen}
        fetchStudents={fetchStudents}
      />
      <StudentEditModal
        userID={openEdit}
        open={!!openEdit}
        close={() => setOpenEdit("")}
        fetchStudents={fetchStudents}
      />
    </Box>
  );
};

export default WeldingStudents;
