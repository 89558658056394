import {
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { baseURL } from "../../../config";
import axios from "axios";
import { useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  studentId: string;
  fetchStudents: () => void;
}

const AttendanceModal = ({
  onClose,
  open,
  studentId,
  fetchStudents,
}: Props) => {
  const [loading, setLoading] = useState({ type: "no", loading: false });
  const checkAttendance = async (status: boolean) => {
    try {
      if (status) {
        setLoading({ loading: true, type: "yes" });
      } else {
        setLoading({ loading: true, type: "no" });
      }

      await axios.post(
        `${baseURL}/welding-student/attendance`,
        { student_id: studentId, is_attendance: status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      onClose();
      toast.success("Attendance check submitted successfully.");
      setLoading({ loading: false, type: "no" });
      fetchStudents();
    } catch (err) {
      console.error("Error checking attendance", err);
      setLoading({ loading: false, type: "no" });
    } finally {
      setLoading({ loading: false, type: "no" });
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog color="primary" layout="center" size="sm" variant="plain">
        <ModalClose />
        <Box sx={{ p: 2 }}>
          <Typography level="h4" component="h2">
            Attendance check has been submitted successfully.
          </Typography>
          <Typography sx={{ textAlign: "center" }} variant="plain">
            {dayjs(new Date()).format("YYYY-MM-DD")}
          </Typography>
          <Box sx={{ display: "flex", pt: 2, justifyContent: "space-between" }}>
            <Button
              onClick={() => checkAttendance(false)}
              startDecorator={<CloseIcon />}
              color="danger"
              loading={loading.loading === true && loading.type === "no"}
            >
              There was no
            </Button>
            <Button
              onClick={() => checkAttendance(true)}
              startDecorator={<CheckIcon />}
              color="success"
              loading={loading.loading === true && loading.type === "yes"}
            >
              There was
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
export default AttendanceModal;
