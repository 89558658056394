import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  SvgIcon,
} from "@mui/joy";
import FileUpload from "../../others/FileUpload";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import { DisplayFlexWrap } from "../../styles/style";
import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { baseURL } from "../../../config";
import { Image } from "@mui/icons-material";
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  close: () => void;
  userID: string;
  fetchStudents: () => void;
}

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

interface Student {
  _id: string;
  name: string;
  passport: string;
  address: string;
  phone: string;
  visa: string;
  id_front: string;
  id_back: string;
  photo_3x4: string;
  consent: string;
  security: string;
  created_at: string;
  updated_at: string;
}

const StudentEditModal = ({ close, open, userID, fetchStudents }: Props) => {
  const [user, setUser] = useState<Student>();
  const [name, setName] = useState("");
  const [passport, setPassport] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [visa, setVisa] = useState("");
  const [id_front, setIdFront] = useState<string | File>("");
  const [id_back, setIdBack] = useState<string | File>("");
  const [photo_3x4, setPhoto3x4] = useState<string | File>("");
  const [updating, setUpdating] = useState(false);

  const handleIdFrontEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIdFront(event.target.files[0] as File);
    }
  };

  const handleIdBackEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIdBack(event.target.files[0] as File);
    }
  };

  const handlePhoto3x4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setPhoto3x4(event.target.files[0] as File);
    }
  };

  const getByUser = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/welding-student/get/${userID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUser(data?.data);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const onSubmit = async () => {
    try {
      setUpdating(true);

      const uploadFile = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        const { data } = await axios.post(`${baseURL}/upload/file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        return data.file_path;
      };

      const idFrontPath =
        typeof id_front === "object" ? await uploadFile(id_front) : id_front;
      const idBackPath =
        typeof id_back === "object" ? await uploadFile(id_back) : id_back;
      const photo3x4Path =
        typeof photo_3x4 === "object" ? await uploadFile(photo_3x4) : photo_3x4;

      setIdFront(idFrontPath);
      setIdBack(idBackPath);
      setPhoto3x4(photo3x4Path);

      const response = await axios.put(
        `${baseURL}/welding-student/update/${userID}`,
        {
          name,
          passport,
          address,
          phone,
          visa,
          id_front: idFrontPath,
          id_back: idBackPath,
          photo_3x4: photo3x4Path,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log("User updated successfully:", response.data);
      close();
      fetchStudents();
      toast.success("User updated successfully.");
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (userID) {
      getByUser();
    }
  }, [userID]);

  useEffect(() => {
    setName(user?.name || "");
    setPassport(user?.passport || "");
    setAddress(user?.address || "");
    setPhone(user?.phone || "");
    setVisa(user?.visa || "");
    setIdFront(user?.id_front || "");
    setIdBack(user?.id_back || "");
    setPhoto3x4(user?.photo_3x4 || "");
  }, [user]);

  return (
    <Modal open={open} onClose={close}>
      <ModalDialog
        color="primary"
        layout="center"
        size="lg"
        variant="plain"
        minWidth={700}
        sx={{
          overflowY: "auto",
        }}
      >
        <ModalClose />
        <Stack spacing={2} sx={{ pt: 2 }}>
          <FormControl>
            <FormLabel>Full Name</FormLabel>
            <Input
              autoFocus
              required
              name="name"
              defaultValue={user?.name}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>ID Number</FormLabel>
            <Input
              autoFocus
              required
              name="passport"
              defaultValue={user?.passport}
              value={passport}
              onChange={(e) => setPassport(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Address</FormLabel>
            <Input
              autoFocus
              required
              name="address"
              defaultValue={user?.address}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Phone Number</FormLabel>
            <Input
              autoFocus
              required
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              defaultValue={user?.phone as string}
            />
          </FormControl>
          <FormControl>
            <FormLabel>VISA</FormLabel>
            <Input
              autoFocus
              required
              name="visa"
              value={visa}
              defaultValue={user?.visa}
              onChange={(e) => setVisa(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>ID oldi tomoni</FormLabel>
            <DisplayFlexWrap style={{ marginTop: 6 }}>
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
                style={{
                  flex: 1,
                  height: 180,
                  color: id_front ? "green" : "inherit",
                }}
              >
                {id_front ? (
                  typeof id_front === "string" ? (
                    <img
                      width={150}
                      height={150}
                      src={id_front}
                      alt="id_back"
                      style={{ objectFit: "cover", width: 150, height: 150 }}
                    />
                  ) : (
                    <img
                      width={150}
                      height={150}
                      src={URL.createObjectURL(id_front)}
                      alt="id_back"
                      style={{ objectFit: "cover", width: 150, height: 150 }}
                    />
                  )
                ) : (
                  "Upload a file"
                )}
                <VisuallyHiddenInput type="file" onChange={handleIdFrontEnd} />
              </Button>
            </DisplayFlexWrap>
          </FormControl>
          <FormControl>
            <FormLabel>ID orqa tomoni</FormLabel>
            <DisplayFlexWrap style={{ marginTop: 6 }}>
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
                style={{
                  flex: 1,
                  height: 180,
                  color: id_back ? "green" : "inherit",
                }}
              >
                {id_back ? (
                  typeof id_back === "string" ? (
                    <img
                      width={150}
                      height={150}
                      src={id_back}
                      alt="id_back"
                      style={{ objectFit: "cover", width: 150, height: 150 }}
                    />
                  ) : (
                    <img
                      width={150}
                      height={150}
                      src={URL.createObjectURL(id_back)}
                      alt="id_back"
                      style={{ objectFit: "cover", width: 150, height: 150 }}
                    />
                  )
                ) : (
                  "Upload a file"
                )}
                <VisuallyHiddenInput type="file" onChange={handleIdBackEnd} />
              </Button>
            </DisplayFlexWrap>
          </FormControl>
          <FormControl>
            <FormLabel>Rasm (4x3)</FormLabel>
            <DisplayFlexWrap style={{ marginTop: 6 }}>
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
                style={{
                  flex: 1,
                  height: 180,
                  color: photo_3x4 ? "green" : "inherit",
                }}
              >
                {photo_3x4 ? (
                  typeof photo_3x4 === "string" ? (
                    <img
                      width={150}
                      height={150}
                      src={photo_3x4}
                      alt="photo_3x4"
                      style={{ objectFit: "cover", width: 150, height: 150 }}
                    />
                  ) : (
                    <img
                      width={150}
                      height={150}
                      src={URL.createObjectURL(photo_3x4)}
                      alt="photo_3x4"
                      style={{ objectFit: "cover", width: 150, height: 150 }}
                    />
                  )
                ) : (
                  "Upload a file"
                )}
                <VisuallyHiddenInput type="file" onChange={handlePhoto3x4} />
              </Button>
            </DisplayFlexWrap>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button onClick={close} variant="outlined">
              Cancel
            </Button>
            <Button loading={updating} onClick={onSubmit}>
              Save
            </Button>
          </Box>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
export default StudentEditModal;
