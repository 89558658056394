import { Box, Button, Sheet, Table } from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import { baseURL } from "../../../../config";
import { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
interface WeldingStudent {
  _id: string;
  name: string;
  attendance: {
    date?: string;
    is_attendance?: boolean;
  }[];
}

const WeldingAttendanceList = () => {
  const [students, setStudents] = useState<WeldingStudent[]>([]);
  const navigate = useNavigate();
  const fetchAllStudents = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/welding-student/attendance`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setStudents(response.data.data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  useEffect(() => {
    fetchAllStudents();
  }, []);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100dvh",
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Button
          onClick={() => navigate("/education/welding-students")}
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Go to Students
        </Button>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          {/* <thead>
            <tr>
              <th
                style={{
                  width: 30,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                T/R
              </th>
              <th
                style={{
                  width: 80,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                Full Name
              </th>
              {new Array(30).fill(" ").map((_, index) => (
                <th
                  style={{
                    width: 20,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                  key={index}
                >
                  {index + 1}
                </th>
              ))}
            </tr>
          </thead> */}
          <tbody>
            {students?.map((student, index) => (
              <tr key={index}>
                {/* <td style={{ textAlign: "center" }}>{index + 1}</td> */}
                <td
                  style={{
                    textAlign: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  {student?.name}
                </td>
                {student?.attendance?.map((atten, subIndex) => (
                  <td style={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {atten?.date}
                      {atten?.is_attendance ? (
                        <CheckBoxIcon color="success" />
                      ) : (
                        <CloseIcon color="error" />
                      )}
                    </Box>
                  </td>
                ))}
                {/* {new Array(30).fill(" ").map((_, attenIndex) => {
                  return (
                    <td style={{ textAlign: "center" }}>
                      {student?.attendance[attenIndex]?.is_attendance ? (
                        <CheckBoxIcon color="success" />
                      ) : (
                        <CloseIcon color="error" />
                      )}
                    </td>
                  );
                })} */}
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </Box>
  );
};
export default WeldingAttendanceList;
